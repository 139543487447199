const drawerWidth = 210;

const styles = theme => ({
  AppRoot: {
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  AppBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    background: "linear-gradient(135deg, #00518D, #39B7D1 80%, #00518D 80%, #39B7D1)"
  },
  AppBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  AppMenuButton: {
    marginLeft: 12,
    marginRight: 12
  },
  DatePicker: {
    width: theme.spacing(20)
  },
  DrawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  DrawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  DrawerIcon: {
    paddingLeft: "24px"
  },
  AppToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...theme.mixins.toolbar
  },
  AppContent: {
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2)
  },
  Paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  FlexGrow: {
    flexGrow: 1
  },
  PointerCursor: {
    cursor: "Pointer"
  },
  TypographyHeight: {
    height: theme.spacing(6)
  },
  TextField: {
    marginTop: 0,
    padding: 0,
    width: theme.spacing(20)
  },
  DiffCalcDatePicker: {
    width: theme.spacing(20),
    marginTop: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  DiffCalcMeteringPointIdField: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(20)
  },
  CircularProgress: {
    color: "grey",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -18,
    marginLeft: -18
  },
  MaxHeight: {
    height: "100%"
  },
  p90Height: {
    height: "90%"
  },
  MarginTop: {
    marginTop: theme.spacing(2)
  },
  MarginBottom: {
    marginBottom: theme.spacing(2)
  },
  MarginLeft: {
    marginLeft: theme.spacing(2)
  },
  MarginRight: {
    marginRight: theme.spacing(2)
  },
  AlignCenter: {
    textAlign: "center"
  },
  AlignLeft: {
    textAlign: "left"
  },
  AlignRight: {
    textAlign: "right"
  },
  Divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  DifferenceCalculationTable: {
    width: "auto",
    tableLayout: "auto"
  },
  Width75Percent: {
    width: "75%"
  },
  Width15Percent: {
    width: "15%"
  },
  Width10Percent: {
    width: "10%"
  },
  Width5Percent: {
    width: "5%"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  NoteTableCell: {
    overflowWrap: "break-word",
    maxWidth: "0"
  },
  LoginScreen: {
    minHeight: "100vh",
    width: "100%"
  },
  ArrowIconButtonsLeft: {
    left: "4px"
  },
  ArrowIconButtonsRight: {
    left: "-8px"
  },
  TagKey: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "rgba(0, 6, 11, 0.57)",
    fontSize: theme.spacing(1.5)
  },
  FabPos: {
    position: "relative",
    left: "90%",
    top: "90%"
  },
  Select: {
    paddingTop: "5px"
  },
  ExportCvs: {
    rowBcWarning: "yellow",
    rowBcError: "red"
  },
  SwitchLabel: {
    fontFamily:"Arial",
    color:"rgba(0,0,0,0.54)",
    fontSize:"12px",
    marginTop:"-6px",
    marginBottom:"-10px",
    marginLeft:"-12px",
    marginRight:"-12px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    borderRadius: 20
  },
  autompleteFormControl: {
    margin: theme.spacing(1),
    minWidth: "80%",
    borderRadius: 20
  }
});

export default styles;
